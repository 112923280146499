"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AtomicCounter = void 0;
const COUNTER_CONTAINER = "counters";
class AtomicCounter {
    api;
    constructor(api) {
        this.api = api;
    }
    /**
     * Gets a unique order number of 6 digits, starting at 100000
     * @returns {Promise<number>} The next unique order number
     */
    async getNextOrderNumber() {
        return this.getIncrementedCount("orderNumber", 100000);
    }
    /**
     * Increments and returns a counter, ensuring that the value is unique by
     * using CommerceTools versions to avoid two requests getting the same value
     * @param key     The key of the counter to increment
     * @param initial The initial value to use if the counter doesn't exist
     * @returns {Promise<number>} The incremented value
     */
    async getIncrementedCount(key, initial = 0) {
        return this.api
            .customObjects()
            .withContainerAndKey({ container: COUNTER_CONTAINER, key })
            .get()
            .execute()
            .catch((err) => {
            // a 404 is fine, we just need to create the counter. anything else is bad.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (err.statusCode !== 404)
                throw err;
            return { body: { value: initial } };
        })
            .then(({ body }) => body)
            .then(({ value, version }) => {
            return this.setCounter(key, value + 1, version);
        })
            .catch((err) => {
            // a 409 is a version mismatch, so we should try again
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (err.statusCode !== 409)
                throw err;
            return this.getIncrementedCount(key);
        });
    }
    /**
     * Attempts to set a counter (within a container) to a specific value
     * @param key       The key of the counter to set
     * @param value     The value to try setting the counter to
     * @param version   The version of the counter we used to get the value
     * @returns {Promise<number>} The value of the counter after setting
     */
    async setCounter(key, value, version) {
        return this.api
            .customObjects()
            .post({
            body: {
                container: COUNTER_CONTAINER,
                key,
                value,
                version,
            },
        })
            .execute()
            .then(({ body }) => body.value);
    }
}
exports.AtomicCounter = AtomicCounter;
