"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildStripeAddress = void 0;
/**
 * Converts CommerceTools address to Stripe format
 */
const buildStripeAddress = (address) => {
    const line1 = [address?.streetName, address?.company].filter(Boolean).join(', ');
    return {
        city: address?.city,
        country: address?.country,
        postal_code: address?.postalCode,
        state: address?.state || "-",
        line1,
        line2: address?.streetNumber || "",
    };
};
exports.buildStripeAddress = buildStripeAddress;
