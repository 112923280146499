"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerIntent = exports.OrderIntent = exports.ShipmentState = exports.ShippingMode = exports.InternalPaymentMethod = exports.TransactionState = exports.TransactionType = exports.OrderStatus = exports.OrderState = exports.OrderPaymentState = void 0;
var OrderPaymentState;
(function (OrderPaymentState) {
    OrderPaymentState["BalanceDue"] = "BalanceDue";
    OrderPaymentState["CreditOwed"] = "CreditOwed";
    OrderPaymentState["Failed"] = "Failed";
    OrderPaymentState["Paid"] = "Paid";
    OrderPaymentState["Pending"] = "Pending";
})(OrderPaymentState || (exports.OrderPaymentState = OrderPaymentState = {}));
var OrderState;
(function (OrderState) {
    OrderState["AwaitingArtwork"] = "awaiting-artwork";
    OrderState["ProcessingArtwork"] = "processing-artwork";
    OrderState["ArtworkIssue"] = "artwork-issue";
    OrderState["AwaitingSubmission"] = "awaiting-submission";
    OrderState["ProcessingSubmission"] = "processing-submission";
    OrderState["SubmissionFailed"] = "submission-failed";
    OrderState["InProduction"] = "in-production";
    OrderState["PartiallyDispatched"] = "partially-dispatched";
    OrderState["Dispatched"] = "dispatched";
    OrderState["Cancelled"] = "Cancelled";
})(OrderState || (exports.OrderState = OrderState = {}));
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["Open"] = "Open";
    OrderStatus["Confirmed"] = "Confirmed";
    OrderStatus["Complete"] = "Complete";
    OrderStatus["Cancelled"] = "Cancelled";
})(OrderStatus || (exports.OrderStatus = OrderStatus = {}));
var TransactionType;
(function (TransactionType) {
    TransactionType["Authorization"] = "Authorization";
    TransactionType["CancelAuthorization"] = "CancelAuthorization";
    TransactionType["Charge"] = "Charge";
    TransactionType["Refund"] = "Refund";
    TransactionType["Chargeback"] = "Chargeback";
})(TransactionType || (exports.TransactionType = TransactionType = {}));
var TransactionState;
(function (TransactionState) {
    TransactionState["Initial"] = "Initial";
    TransactionState["Pending"] = "Pending";
    TransactionState["Success"] = "Success";
    TransactionState["Failure"] = "Failure";
})(TransactionState || (exports.TransactionState = TransactionState = {}));
var InternalPaymentMethod;
(function (InternalPaymentMethod) {
    InternalPaymentMethod["Cash"] = "CASH";
    InternalPaymentMethod["Credit"] = "CREDIT";
})(InternalPaymentMethod || (exports.InternalPaymentMethod = InternalPaymentMethod = {}));
var ShippingMode;
(function (ShippingMode) {
    ShippingMode["Single"] = "Single";
    ShippingMode["Multiple"] = "Multiple";
})(ShippingMode || (exports.ShippingMode = ShippingMode = {}));
var ShipmentState;
(function (ShipmentState) {
    ShipmentState["Shipped"] = "Shipped";
    ShipmentState["Delivered"] = "Delivered";
    ShipmentState["Ready"] = "Ready";
    ShipmentState["Pending"] = "Pending";
    ShipmentState["Delayed"] = "Delayed";
    ShipmentState["Partial"] = "Partial";
    ShipmentState["Backorder"] = "Backorder";
})(ShipmentState || (exports.ShipmentState = ShipmentState = {}));
var OrderIntent;
(function (OrderIntent) {
    OrderIntent["Standard"] = "standard";
    OrderIntent["Reprint"] = "reprint";
    OrderIntent["Legacy"] = "legacy";
    OrderIntent["Test"] = "test";
})(OrderIntent || (exports.OrderIntent = OrderIntent = {}));
var CustomerIntent;
(function (CustomerIntent) {
    CustomerIntent["Standard"] = "standard";
    CustomerIntent["Test"] = "test";
})(CustomerIntent || (exports.CustomerIntent = CustomerIntent = {}));
